/* Mobile first */
.footer {
  display: grid;
  grid-template-areas:
    'left'
    'middle'
    'form'
    'logos';
}

/* Tablet - 48em = 768px */
@media (min-width: 48em) {
  .footer {
    grid-template-areas:
      'left middle'
      'form form'
      'logos logos';
  }
}

/* Desktop - 64em = 1024px */
@media (min-width: 64em) {
  .footer {
    grid-template-areas:
      'left middle form form'
      'logos middle form form';
  }
}
